<template>
  <div>
    <v-row
      v-for="task in taskList"
      :key="task.id"
    >
      <v-col
        cols="12"
      >
        <task-card
          :task-item="task"
          class="mb-4"
        ></task-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from '@axios'
import { ref } from '@vue/composition-api'
import TaskCard from '../components/TaskCard.vue'
import config from '../../../../config/index'

export default {
  name: 'DashBoardsToday',
  components: {
    TaskCard,
  },
  setup() {
    const taskList = ref([])

    function fetchItems(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          // .get('/task/task/workPointPagesCurrent', { params: queryParams })
          .get(`${config.serviceUrl}/task/work/workPointPagesCurrent`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
    fetchItems({ page: 1, pageSize: -1 }).then(response => {
      const { data } = response
      taskList.value = data.data

      console.log(data)
    })

    return {
      taskList,
    }
  },
}
</script>

<style scoped>

</style>
